import { getRedirectAfterLoginUrl } from 'hooks/useAutoLogout'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

const redirectRoute = '/patients'

export default function IndexPage() {
  const router = useRouter()

  useEffect(() => {
    // Get redirectAfterLogin query param or localStorage value
    const redirectUrl = getRedirectAfterLoginUrl({
      query: router.query,
    })

    // Prevent redirect if we are already redirecting from useAutoLogout.tsx
    if (redirectUrl) {
      return
    }

    const startRedirection = async () => {
      // Temporarily redirect to /patients until we have a dashboard
      await router.replace(redirectRoute)
      console.warn(`Redirected from / to: ${redirectRoute}`)
    }

    startRedirection()
  }, [router])

  return null
}
